<template>
    <div class="home">
        <div :style="{ padding: homepadding }">
            <el-row class="myhomerow">
                <el-col :span="16" :xs="0" :sm="0" :md="16" :lg="16">
                    <div class="homeleft" :style="{ height: imgheight }"></div>
                </el-col>
                <el-col :span="8" :xs="24" :sm="24" :md="8" :lg="8">
                    <div class="homeright" :style="{ height: imgheight }">
                        <div :style="{ padding: rightpadding }">
                            <div style="margin-bottom: 20px">
                                <span style="color: #616e9d; font-weight: bolder; font-size: 30px">共享打印商户平台</span>
                            </div>
                            <el-form ref="seniorform" :model="seniorform" :rules="seniorformrules">
                                <el-form-item>
                                    <el-form-item style="margin-bottom: 20px" prop="username">
                                        <el-input v-model="seniorform.username" placeholder="请输入用户名" maxlength="40" clearable @clear="clearInputEvent">
                                            <template #prepend><i class="iconfont icon-dengluye-zhanghao" style="color: #616e9d"></i> </template>
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item style="margin-bottom: 10px" prop="password">
                                        <el-input v-model="seniorform.password" type="password" autocomplete="off" placeholder="请输入密码" show-password clearable maxlength="40">
                                            <template #prepend><i class="iconfont icon-dengluye-mima" style="color: #616e9d"></i></template>
                                        </el-input>
                                    </el-form-item>
                                    <div class="forget-box">
                                        <div>
                                            <el-checkbox label="记住密码" name="remember" v-model="seniorform.remember"></el-checkbox>
                                        </div>
                                        <div class="forget-box-zi">
                                            <el-button type="text" @click="routeraddrClick('/loginforget')">忘记密码？</el-button>
                                        </div>
                                    </div>
                                </el-form-item>
                                <el-form-item>
                                    <div>
                                        <el-button type="primary" class="loginbutton" @click="myloginbutton('seniorform')">
                                            登录
                                        </el-button>
                                    </div>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="myfooterbttom">
            <span
                >©2017-{{ this.footeryear }} 京硕信息版权所有 ICP证:
                <a style="text-decoration: none" href="https://beian.miit.gov.cn/" target="_blank">豫ICP备17032092号-1</a></span
            >
        </div>

        <!-- 弹出-提示框 -->
        <div>
            <tanDialog :DialogVisible="tanDialog.DialogVisible" :Title="tanDialog.Title" :Width="tanDialog.Width" :Message="tanDialog.Message">
                <template v-slot:footer>
                    <el-button size="small" @click="noBowOutEvent(), (tanDialog.DialogVisible = false)">暂不退出</el-button>
                    <el-button type="primary" size="small" @click="bowOutEvent(), (tanDialog.DialogVisible = false)">立即退出</el-button>
                    <el-button type="warning" size="small" @click="outAndLoginEvent(), (tanDialog.DialogVisible = false)">退出并登录新账号</el-button>
                </template>
            </tanDialog>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from 'axios'
import { ElLoading, ElMessageBox } from 'element-plus'

export default defineComponent({
    name: 'login',
    data() {
        return {
            seniorform: {
                username: '',
                password: '',
                remember: false
            },
            seniorformrules: {
                username: [
                    {
                        required: true,
                        message: '账号不能为空',
                        trigger: 'blur'
                    }
                ],
                password: [
                    {
                        required: true,
                        message: '密码不能为空',
                        trigger: 'blur'
                    }
                ]
            },
            // issuper: false,
            passwd: '',
            loginleftimg: 'url("../../assets/imgs/login/loginleft.png") center center no-repeat',
            loginrightimg: 'url("../../assets/imgs/login/loginright.png") center center no-repeat',
            homepadding: '10% 20%',
            imgheight: '550px',
            sreenheight: null,
            sreenwidth: null,
            mywidth: '1160px',
            myheight: '580px',
            rightpadding: '40% 10% 0',

            logintoken: '',

            // 弹窗
            tanDialog: {
                DialogVisible: false,
                Title: '',
                Width: '',
                Message: ''
            },
            superuser_data: '',
            superuser_pass: '',

            // 备案到当前年
            footeryear: ''
        }
    },
    mounted() {},
    methods: {
        // 暂不退出
        noBowOutEvent() {
            this.automaticLoginEvent(this.superuser_data, localStorage.getItem('token'), localStorage.getItem('is_model_login'))
        },
        // 立即退出
        bowOutEvent() {
            localStorage.clear()
            this.$router.push('/').catch((error) => error)
        },
        // 退出并登录新账号
        outAndLoginEvent() {
            localStorage.clear()
            this.automaticLoginEvent(this.superuser_data, this.superuser_pass, 0)
        },

        // 点击忘记密码-跳转页面
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr) {
            //仅跳转
            this.$router.push(routeraddr).catch((error) => error)
        },
        // 点击回车-登录
        onkeydownEvent() {
            document.onkeydown = (e) => {
                e = window.event || e
                if (e.keyCode === 13) {
                    this.myloginbutton('seniorform')
                }
            }
        },
        // 点击清除按钮事件
        clearInputEvent() {
            this.seniorform = {
                username: '',
                password: '',
                remember: false
            }
        },
        myloginbutton(formName) {
            let forminfo = this.$refs[formName].model
            // 有token直接登录
            if (localStorage.getItem('userinfo') && JSON.parse(localStorage.getItem('userinfo')).username != forminfo.username) {
                ElMessageBox.confirm('已有账号登录，是否退出当前账号', '提示', {
                    confirmButtonText: '立即退出',
                    cancelButtonText: '暂不退出',
                    type: 'warning'
                })
                    .then(() => {
                        localStorage.clear()
                    })
                    .catch(() => {
                        location.reload()
                    })
            } else {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        //加载loading
                        var loading = ElLoading.service({
                            lock: true,
                            text: '正在登录...'
                        })
                        var username = forminfo.username
                        var password = forminfo.password

                        axios
                            .post('/shop/User/login', {
                                username: username,
                                password: password
                            })
                            .then((response) => {
                                //函数格式
                                console.log(response.data)
                                if (response.data.code === 0) {
                                    var userinfo = response.data.result
                                    var gettoken = response.data.result.Authen

                                    localStorage.setItem('token', gettoken) //存储token

                                    //登录成功之后，进入后台,通过token得到相关的models
                                    axios
                                        .get('/shop/Equipment/menu', {
                                            headers: { Authen: gettoken }
                                        })
                                        .then((response) => {
                                            if (response.data.code == 0) {
                                                this.setUserInfo()
                                                localStorage.setItem('mymodels', JSON.stringify(response.data.result))
                                                // localStorage.setItem("token", gettoken); //存储token
                                                localStorage.setItem('userinfo', JSON.stringify(userinfo)) //存储用户信息
                                                localStorage.setItem('is_model_login', 0)
                                                this.$router.push('/printoverview').catch((error) => error)
                                                setTimeout(() => {
                                                    loading.close()
                                                }, 500)
                                            } else {
                                                this.$router.push('/').catch((error) => error)
                                            }
                                        })
                                        .catch(function(error) {
                                            console.log(error)
                                            loading.close()
                                        })
                                } else {
                                    loading.close()
                                }
                            })
                            .catch(function(error) {
                                console.log(error)
                                loading.close()
                            })
                    } else {
                        console.log('error submit!!')
                        loading.close()
                        return false
                    }
                })
            }
        },
        // 存储账户密码
        setUserInfo() {
            let Base64 = require('js-base64').Base64
            let username = Base64.encode(this.seniorform.username) //账号加密
            let password = Base64.encode(this.seniorform.password) //密码加密

            // 判断用户是否勾选记住密码，如果勾选，向cookie中储存登录信息，
            // 如果没有勾选，储存的信息为空
            // 设置7天保存密码
            if (this.seniorform.remember) {
                this.setCookie('dominate_v3', username, 7)
                this.setCookie('cypher_v3', password, 7)
                this.setCookie('username', '还想看密码', 1)
                this.setCookie('password', '没门儿', 1)
            } else {
                this.setCookie('dominate_v3', '')
                this.setCookie('cypher_v3', '')
                this.setCookie('username', '')
                this.setCookie('password', '')
            }
        },
        // 获取cookie
        getCookie(key) {
            if (document.cookie.length > 0) {
                var datas = document.cookie.split('; ')
                for (var i = 0; i < datas.length; i++) {
                    var value = datas[i].split('=')
                    if (value[0] === key) {
                        return value[1]
                    }
                }
            } else {
                return false
            }
        },
        // 保存cookie  - exdate.setTime(exdate.getTime() - 10);[10s清除]
        setCookie(cName, value, expiredays) {
            var exdate = new Date()
            exdate.setDate(exdate.getDate() + expiredays)
            document.cookie = cName + '=' + decodeURIComponent(value) + (expiredays == null ? '' : ';expires=' + exdate.toGMTString())
        },
        //登录页的自动检测切换状态
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            // console.log(this.sreenwidth, this.sreenheight)
            if (this.sreenwidth > 1200) {
                this.homepadding = '9% 18%'
                this.imgheight = this.sreenheight * 0.6 + 'px'
                this.rightpadding = '40% 10% 0'
            } else {
                if (this.sreenwidth > 992) {
                    if (this.sreenheight > 1300) {
                        //适应iPadpro-h
                        this.homepadding = '35% 10%'
                        this.imgheight = this.sreenheight * 0.4 + 'px'
                        this.rightpadding = '40% 10% 0'
                    } else {
                        this.homepadding = '8% 10%'
                        this.imgheight = this.sreenheight * 0.7 + 'px'
                        this.rightpadding = '40% 10% 0'
                    }
                } else {
                    if (this.sreenwidth > 766) {
                        if (this.sreenheight < 420) {
                            //适应pixel2XL-h
                            this.homepadding = '4% 25%'
                            this.imgheight = this.sreenheight * 0.85 + 'px'
                            this.rightpadding = '8% 10% 0'
                        } else {
                            this.homepadding = '15% 20%'
                            this.imgheight = this.sreenheight * 0.7 + 'px'
                            this.rightpadding = '40% 10% 0'
                        }
                    } else {
                        if (this.sreenheight < 550) {
                            //适应surfceduo-h
                            this.homepadding = '2% 20%'
                            this.imgheight = this.sreenheight * 0.93 + 'px'
                            this.rightpadding = '40% 10% 0'

                            if (this.sreenheight < 420) {
                                //适应MotoG4-h
                                this.homepadding = '4% 25%'
                                this.imgheight = this.sreenheight * 0.85 + 'px'
                                this.rightpadding = '8% 10% 0'
                            }
                            if (this.sreenheight < 330) {
                                //适应iPhone5/SE-h
                                this.homepadding = '2% 20%'
                                this.imgheight = this.sreenheight * 0.9 + 'px'
                                this.rightpadding = '2% 10% 0'
                            }
                            if (this.sreenheight < 290) {
                                //适应iPhone5/SE-h
                                this.homepadding = '0 20%'
                                this.imgheight = this.sreenheight + 'px'
                                this.rightpadding = '0 10%'
                            }
                        } else {
                            this.homepadding = '20% 13%'
                            this.imgheight = this.sreenheight * 0.65 + 'px'
                            this.rightpadding = '30% 10% 0'
                        }
                    }
                }
            }
        },

        // 自动登录
        automaticLoginEvent(superuser, superuser_passwd, is_model_login_istrue) {
            this.seniorform.username = superuser
            this.seniorform.password = '*******'
            this.seniorform.remember = false
            // this.issuper = true;
            this.passwd = superuser_passwd
            let gettoken1 = this.passwd
            //加载loading
            let loading = ElLoading.service({
                lock: true,
                text: '正在登录...'
            })
            axios
                .get('/shop/Equipment/menu', { headers: { Authen: gettoken1 } })
                .then((response) => {
                    if (response.data.code == 0) {
                        this.setUserInfo()
                        localStorage.setItem('mymodels', JSON.stringify(response.data.result))
                        localStorage.setItem('token', gettoken1) //存储token
                        localStorage.setItem('userinfo', JSON.stringify(response.data.message)) //存储用户信息
                        localStorage.setItem('is_model_login', is_model_login_istrue)
                        this.$router.push('/account').catch((error) => error)
                        setTimeout(() => {
                            loading.close()
                        }, 500)
                    } else {
                        this.$router.push('/').catch((error) => error)
                        loading.close()
                    }
                })
                .catch(function(error) {
                    console.log(error)
                    loading.close()
                })
        }
    },
    created() {
        window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
        this.getwindowinfo()
        let Base64 = require('js-base64').Base64

        // 获取当前年
        let now = new Date()
        this.footeryear = now.getFullYear()

        // 在页面加载时从cookie获取登录信息
        var username = this.getCookie('dominate_v3')
        var password = this.getCookie('cypher_v3')
        // 如果存在，赋值给表单，并且将记住密码勾选
        if (username && password) {
            this.seniorform.username = Base64.decode(username)
            this.seniorform.password = Base64.decode(password)
            this.seniorform.remember = true
        }

        // 点击回车-登录
        this.onkeydownEvent()

        if (!this.$route.query.username) {
            // 有token直接登录
            if (localStorage.getItem('token') && localStorage.getItem('token') != '') {
                this.$route.query.username = JSON.parse(localStorage.getItem('userinfo'))['username']
                this.$route.query.passwd = localStorage.getItem('token')
            }
        }

        // 来源租户后台-路由跳转登录
        var superuser = this.$route.query.username
        var is_model_login = this.$route.query.model
        if (superuser) {
            // 模拟登录-提示为模拟登录
            if (localStorage.getItem('token') && localStorage.getItem('token') != '') {
                if (is_model_login && is_model_login == 'modelLogin') {
                    // 弹窗-当前已存在登录用户，请退出后，再进行模拟登录
                    this.tanDialog = {
                        DialogVisible: true,
                        Title: '提示',
                        Width: '30%',
                        Message: '当前已存在登录用户，请退出后，再进行模拟登录，是否退出当前账号？'
                    }

                    this.superuser_data = superuser
                    this.superuser_pass = this.$route.query.passwd

                    //   // 当前已存在登录用户，请退出后，再进行模拟登录
                    //   ElMessageBox.confirm('当前已存在登录用户，请退出后，再进行模拟登录，是否退出当前账号？', '提示', {
                    //     confirmButtonText: '立即退出',
                    //     cancelButtonText: "暂不退出",
                    //     type: "warning",
                    //   }).then(() => {
                    //     localStorage.clear();
                    //     this.$router.push("/").catch((error) => error);
                    //   }).catch(() => {
                    //     this.automaticLoginEvent(superuser, localStorage.getItem('token'),localStorage.getItem('is_model_login'))
                    //   })
                } else {
                    this.automaticLoginEvent(superuser, this.$route.query.passwd, 0)
                }
            } else {
                this.automaticLoginEvent(superuser, this.$route.query.passwd, 0)
            }
        }

        // 忘记密码页面-跳转传参-赋返显值
        if (this.$route.query.phonenum) {
            this.seniorform.username = this.$route.query.phonenum
        }
    },

    components: {}
})
</script>

<style scoped>
.home {
    width: 100%;
    height: 100%;
    background: url('../../assets/imgs/login/loginbag.png') center center no-repeat;
    background-size: 100% 100%;
    position: fixed;
    left: 0;
    top: 0;
}

.myhomerow {
    box-shadow: -20px 30px 50px -10px #0b3434;
    border: 2px solid white;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
}

.homeleft {
    width: 100%;
    background: url('../../assets/imgs/login/loginleft.png') center center no-repeat;
    background-size: 100% 100%;
    border-radius: 15px;
}

.homeright {
    width: 100%;
    background: url('../../assets/imgs/login/loginright.png') center center no-repeat;
    background-size: 100% 100%;
    border-radius: 12px;
}

.loginbutton {
    width: 100%;
    border-radius: 50px;
    background-image: linear-gradient(to right, #17bbff, #1adeaf);
    border: none;
}

.myfooterbttom {
    text-align: center;
    font-size: 12px;
}

/* 记住密码&忘记密码 */
.forget-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.forget-box-zi .el-button {
    color: #333333;
}

.forget-box-zi .el-button:hover {
    cursor: pointer;
    color: var(--el-color-primary);
}
</style>
